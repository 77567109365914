import styled from 'styled-components';

import colorTheme from '../../style/colorTheme';
import themeGet from '../../style/themeGet';
import { ITheme } from '../../types/types';
import { Caption, H1, H2 } from '../Atoms/Typography/Typography';

export const ParentContainer = styled.div<ITheme>`
  ${({ ezratheme }) => colorTheme(ezratheme)};
  background-color: var(--background);
  display: grid;
  height: 100vh;
`;

export const StyledContainer = styled.div`
  background-color: var(--background);
  display: grid;
  grid-column: 1 / -1;
  padding-top: ${themeGet('spacing.mobile.s1')};
`;

export const StyledCaption = styled(Caption)`
  color: var(--highlight);
`;

export const StyledH1Container = styled.div`
  display: grid;
  grid-column: 1 / -1;
  margin-top: 8px;
`;

export const StyledH1 = styled(H1)`
  color: var(--primary);
`;

export const StyledH2Container = styled.div`
  display: grid;
  grid-column: 1 / -1;
  margin-top: ${themeGet('spacing.mobile.s2')};
`;

export const StyledH2 = styled(H2)`
  color: var(--primary);
`;

export const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: 150px 150px;
  grid-gap: ${themeGet('spacing.mobile.s6')};
  margin: ${themeGet('spacing.mobile.s4')} 0 ${themeGet('spacing.s1')};
`;

export const ImageParentContainer = styled.div`
  display: grid;
  grid-column: 1 / -1;
  background-color: var(--highlight);
`;

export const LogoContainer = styled.div`
  display: grid;
  grid-column: 1 / 3;
  padding: ${themeGet('spacing.mobile.s1')} 0;

  path {
    fill: var(--background);
  }
`;
